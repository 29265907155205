import { alpha } from '@mui/material/styles'

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  light: 'rgba(0, 0, 0, 0.85)',
  lighter: 'rgba(0, 0, 0, 0.50)',
}

const primaryColor = '#681d49'
const secondaryColor = '#037686'

// const lightenV1 = (color, percent) => {
//   const num = parseInt(color.slice(1), 16)
//   const amt = Math.round(2.55 * percent)
//   const R = (num >> 16) + amt
//   const G = ((num >> 8) & 0x00ff) + amt
//   const B = (num & 0x0000ff) + amt
//   return `#${(0x1000000 + (R << 16) + (G << 8) + B).toString(16).slice(1)}`
// }

// const darkenV1 = (color, percent) => {
//   const num = parseInt(color.slice(1), 16)
//   const amt = Math.round(2.55 * percent)
//   const R = (num >> 16) - amt
//   const G = ((num >> 8) & 0x00ff) - amt
//   const B = (num & 0x0000ff) - amt
//   return `#${(0x1000000 + (R << 16) + (G << 8) + B).toString(16).slice(1)}`
// }

const lighten = (color, percent) => {
  const num = parseInt(color.slice(1), 16)
  const amt = Math.round(2.55 * percent)

  const R = Math.min(255, (num >> 16) + amt)
  const G = Math.min(255, ((num >> 8) & 0x00ff) + amt)
  const B = Math.min(255, (num & 0x0000ff) + amt)

  return `#${((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1)}`
}

const darken = (color, percent) => {
  const num = parseInt(color.slice(1), 16)
  const amt = Math.round(2.55 * percent)

  const R = Math.max(0, (num >> 16) - amt)
  const G = Math.max(0, ((num >> 8) & 0x00ff) - amt)
  const B = Math.max(0, (num & 0x0000ff) - amt)

  return `#${((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1)}`
}

const PRIMARY = {
  lighter: lighten(primaryColor, 20),
  light: lighten(primaryColor, 10),
  main: primaryColor,
  dark: darken(primaryColor, 10),
  darker: darken(primaryColor, 20),
  contrastText: '#FFFFFF',
}

const SECONDARY = {
  lighter: lighten(secondaryColor, 20),
  light: lighten(secondaryColor, 10),
  main: secondaryColor,
  dark: darken(secondaryColor, 10),
  darker: darken(secondaryColor, 20),
  contrastText: '#FFFFFF',
}

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
}

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
}

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
}

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  }

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  }

  return mode === 'light' ? light : dark
}
